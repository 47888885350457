import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css"

export default function Carousel1() {

    return (
        <div className="gallary-image">
        <div className="container">
            <div className="section-title">
                <h2>Gallery</h2>
            </div>
            <Carousel className="crsl" autoPlay
                infiniteLoop
                centerMode
                centerSlidePercentage={100}
                interval={2000} showThumbs={false} >
                <div>
                    <img src="img/annwesha/img0.jpg" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="img/annwesha/img5.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src="img/annwesha/img1.jpg" />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <img src="img/annwesha/img2.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src="img/annwesha/img3.jpg" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
               
            </Carousel>

        </div>

        </div>
    )

}



