import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Event } from "./components/eventpage";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import Carousel1 from "./components/Carousel";
import { Schedule } from "./components/schedule";
import { Sponsors } from "./components/sponsors";
import Comingpage from "./components/coming";
import Home from "./components/home";
import { Routes, Route, Outlet, Link, BrowserRouter } from "react-router-dom";

import ErrorPage from "./components/errorpage"
import SponsorsPage from "./components/sponsorpage"
import { Advisorybody } from "./components/Advisorybody";
import { Submit } from "./components/Submit";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


function App() {
  return (
    <div>
      <Navigation/>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/advisory-body" element={<Advisorybody/>}/>
        <Route path="/submit" element={<Submit/>}/>
        <Route path="/*" element={<ErrorPage/>}/>
      </Routes>
      </BrowserRouter>
      </div>
  );
}

export default App