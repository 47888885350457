import React from "react";

export const AboutDept = (props) => {
  return (
    <div id="about">
      <div className="about-container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/annwesha/sscollege.jpg" className="img-responsive" alt=""  style={{
            //Below lines will help to set the border radius
            borderBottomLeftRadius: 30,
            borderBottomRightRadius: 15,
            borderTopRightRadius: 30,
            borderTopLeftRadius: 15,
            overflow: 'hidden',
          }} />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{padding:10}}>About the College & Department</h2>
              <p style={{textAlign: 'justify'}}>{props.data ? props.data.paragraph : "loading..."}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
