
import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Event } from "./eventpage";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import Carousel1 from "./Carousel";
import { Schedule } from "./schedule";
import { Sponsors } from "./sponsors";
import Comingpage from "./coming";
import { Sponsorneed } from "./sponsorneed";
import { PrevSponsors } from "./PrevSponsors";

export const Submit = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div id="sponsor">
      <div className="sponsor-container">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="about-text">
              <h2 style={{ padding: 10 }}></h2>
              <h2>Submission Process</h2>


            </div>
            <div className="list-style">
              <div className="col-lg-12 col-sm-12" style={{ textAlign: 'justify', fontSize: 18 }}>

                <p style={{ textAlign: 'justify', fontSize: 18 }}>The journal Anwesha is devoted the publication of original works. The submission of aforesaid works to this journal implies that neither it has been published elsewhere previously nor is under communication for publication. Authors interested to submit their papers should send their articles. through online in Avro software, font size – 12.</p>

                <li>Title should be brief and informative.</li>

                <li>Authors name: Name must be followed by Designation, postal address including email ID and phone Number.</li>

                <li>Plagiarism: As the journal has an ISSN, the authors are requested to avoid Plagiarism. In case of detection of plagiarism, Author's himself will be solely responsible.</li>
               
               <h3>Review Process</h3>
                <p style={{ textAlign: 'justify', fontSize: 18 }}>The review princess involves a coordination involving the Editor, Editorial Board, and the experts from different institutions. A paper is published only after the recommendations of the members involved in the review process. Intimation regarding the selection or rejection of the articles will be only through email.</p>
<ul>Important points to note.</ul>
                <li> The word limit of the articles must be within 2500-3000 words.</li>

                <li>The authors must use Avro keyboard and Kalpurush font and The bibliography should be in MLA style.</li>

                <li>Editors reserves the right to make corrections of changes in the articles.</li>
                <li>Papers accepted only after the consent of the reviewers..</li>
                <h3>Submission Details</h3>
                <p style={{ textAlign: 'justify', fontSize: 18 }}>Download the Submission form:  <a href="https://drive.google.com/file/d/1f01rCwkmyXNauq5beFcfjf_u3-lvaJrh/view">Download Now</a></p>
<ul>Please submit your articles along with the Submission form to this email:  annweshajournal@gmail.com</ul>
                

              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
