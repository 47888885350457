
import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Event } from "./eventpage";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import Carousel1 from "./Carousel";
import { Schedule } from "./schedule";
import { Sponsors } from "./sponsors";
import Comingpage from ".//coming";
import { Sponsorneed } from "./sponsorneed";
import { PrevSponsors } from "./PrevSponsors";

export const Advisorybody = (props) => {

  const people = [
'11.	Prof. Bela Das, Department of Bengali, Assam University, Silchar.',
'2.	Prof. Biswatosh Choudhury, Department of Bengali, Assam University, Silchar.',
'3.	Prof. Munmun Gangopadhyay , Department of Bengali, Rabindra Bharati University, Kolkata',
'4.	Prof. Priyakanta Nath, Department of Bengali, Assam University, Silchar.',
'5.	Prof Debashis Bhattacharjee, Department of Bengali, Assam University, Silchar.',
'6. Prof. Sucharita Bandopadhyay, Department of Bengali, University of Calcutta, Kolkata',
'7. Prof Amitabha Chakraborty, Dean Faculty of Arts, Delhi University, Delhi',
'8. Prof Prakash Kumar Maity, Department of Bengali, Faculty of Arts, Benaras Hindu University, Baranasi',
'9. Prof Nanda Kumar Bera, Retd. Prof. Department of Bengali, Ranchi University, Ranchi',
'10.	Prof. Santanu Sarkar, Department of Bengali, Assam University, Silchar.',
'11.	Dr. Bubul Sharma, Department of Bengali, Assam University, Silchar.',
'12.	Dr. Ramakanta Das, Department of Bengali, Assam University, Silchar.',
'13.	Dr. Rami Chakraborty, Department of Bengali, Assam University, Silchar.',
'14.	Dr. Sanjay Bhattacharjee, Department of Bengali, Gauhati University, Guwahati.',
'15.	Dr. Prasanta Chakraborty, Department of Bengali, Cotton State University, Guwahati.',
'16.	Dr. Prasun Barman, Department of Bengali,, Cotton State University, Guwahati.',
'17.	Dr. Padma Kumari Chakma, Department of Bengali, Tripura University, Agartala.',
'18.	Dr. Debalina Debnath, Department of Folklore, Kalyani University, Nadia, West Bengal.',
'19.	Prof. Dipak Kumar Roy, Vice Chancellor, Raiganj University, Raiganj, West Bengal.',
'20.	Dr. Sougata Chattopadhyay, Department of Bengali, Ramsaday College, -Howrah, West Bengal.',
'21.	Dr. Sekh Makbul Islam, Department of Bengali,  St. Paul\'s Cathedral Mission College, Kolkata.',


  ];

  const [landingPageData, setLandingPageData] = useState({});

  const listItems = people.map(person =>
    <li>{person}</li>
  );



  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div id="sponsor">
      <div className="sponsor-container">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="about-text">
              <h2 style={{ padding: 10 }}></h2>
              <h2>Advisory Body</h2>


            </div>
            <div className="list-style">
              <div className="col-lg-12 col-sm-12" style={{ textAlign: 'justify', fontSize: 18 }}>
                <ol>
                {listItems}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
